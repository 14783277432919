<template lang="pug">
om-modal(name="add-theme-color" :width="420" color="light" @beforeOpen="beforeOpen")
  template(slot="modal-header")
    .row
      .col
        .font-weight-bold.font-size-1--25 {{ $t('addNewColor') }}
    .brand-modal-action-icon
      span.cursor-pointer(@click="$modal.hide('add-theme-color')" title="close" aria-hidden="true")
        close-icon(:width="12" :height="12")
  template(slot="modal-body")
    .d-flex.flex-column
      .col
        .row.align-items-center
          span.small-alt-title.mr-1 {{ $t('colorName') }}:
          span.color-name {{ colorName }}
      .col.mt-3
        .row.align-items-center
          .saturation-container
            saturation(v-model="colors" @change="childChange" :hue="currentHue")
      .col.mt-3
        .row.align-items-center
          .hex-container
            ed-in(label="Hex" :value="hex" @change="inputChange")
      .col.mt-3
        .row.align-items-center
          .hue-container
            hue(v-model="colors" @change="childChange")
  template(slot="modal-footer")
    .d-flex.justify-content-end
      om-button.mr-3(ghost @click="hideModal") {{ $t('cancel') }}
      om-button(primary @click="setNewThemeColor()") {{ $t('addThemeColor') }}
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import tinycolor from 'tinycolor2';
  import colorMixin from '../../color/mixin/color';

  export default {
    components: {
      saturation: () => import('../../color/components/common/Saturation.vue'),
      'ed-in': () => import('../../color/components/common/EditableInputOm.vue'),
      hue: () => import('../../color/components/common/Hue.vue'),
    },
    mixins: [colorMixin],
    props: {
      value: {
        type: String,
        default: 'rgb(255, 0, 0)',
      },
    },
    data() {
      return {
        currentHue: 0,
        colorName: '',
        newColorIndex: 0,
        newColor: '',
      };
    },
    computed: {
      ...mapState(['isEditor', 'globalStyle']),
      hex() {
        return this.colors.hex;
      },
    },
    methods: {
      ...mapMutations(['changeFormManagerVisibility']),
      hideModal() {
        this.$modal.hide('add-theme-color');
      },
      isWhite(data) {
        return data.source === 'hsl' && data.s === 0 && data.l > 0.5;
      },
      isBlack(data) {
        return data.source === 'hsl' && data.s === 0 && data.l < 0.5;
      },
      beforeOpen() {
        const NAMES = [
          this.$t('brandColors.light'),
          this.$t('brandColors.dark'),
          this.$t('brandColors.neutral1'),
          this.$t('brandColors.neutral2'),
        ];
        this.currentHue = this.colors.hsl.h;
        this.newColorIndex = this.globalStyle.palette.themeColors.length;
        this.colorName = NAMES[this.newColorIndex];
        this.childChange(this.newColor || this.colors);
      },
      setNewThemeColor() {
        this.$store.commit('updateThemeColor', {
          themeColor: this.newColor,
          name: this.colorName,
          index: this.newColorIndex,
        });
        this.hideModal();
      },
      childChange(data) {
        if (this.isWhite(data)) data = { h: data.h, s: 0.1, l: 0.9, source: 'hsl', a: 1 };
        if (this.isBlack(data)) data = { h: data.h, s: 0.1, l: 0.1, source: 'hsl', a: 1 };

        const rgba = tinycolor(data).toRgbString();
        this.newColor = rgba;

        this.colorChange(rgba);
      },
      inputChange(data) {
        if (!data) {
          return;
        }
        if (data.hex && this.isValidHex(data.hex)) {
          this.childChange(data.hex);
        } else if (data.r || data.g || data.b || data.a) {
          const rgba = {
            r: data.r || this.colors.rgba.r,
            g: data.g || this.colors.rgba.g,
            b: data.b || this.colors.rgba.b,
            a: data.a || this.colors.rgba.a,
            source: 'rgba',
          };
          this.childChange(rgba);
        }
      },
    },
  };
</script>
<style lang="sass" scoped>
  .small-alt-title
    font-family: Roboto
    font-style: normal
    font-weight: normal
    font-size: 15px
    line-height: 24px
    color: #272727
  .color-name
    font-size: 12px
    color: #505763
    margin-top: 1px
  .saturation-container
    width: 100%
    height: 8.75rem
    border: 1px solid #E3E5E8
    border-radius: 8px
    ::v-deep.vc-saturation
      border-radius: 8px
  .hue-container
    height: 0.75rem
    width: 21.25rem
    border: 1px solid #D5D8DD
    border-radius: 4px
  ::v-deep.vc-saturation--black
    border-radius: 8px
  ::v-deep.vc-input__input
    min-width: 5rem
</style>
